import * as React from "react";
import Main from "@components/main";
import data from "@/data/main";
import { Helmet } from "react-helmet";

const IndexPage = () => (
  <>
    <Helmet>
      <meta name="facebook-domain-verification" content="tw76qynjvqvsoqnkv73pvfu3vpvzpj" />
    </Helmet>
    <Main data={data} lang={'ru'} pageTitle={"Foretell"} />
  </>
);

export default IndexPage;